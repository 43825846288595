import React, { Suspense } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { Navigate } from "react-router-dom";
import { store } from "./Redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

const AddNewJobAssignments = React.lazy(() => import("./Components/AddNewJobAssignments"));
const AdminLandingPage = React.lazy(() => import("./Components/AdminLandingPage"));
const AssistantLandingPage = React.lazy(() => import("./Components/AssistantLandingPage"));
const CompanyRegistration = React.lazy(() => import("./Components/CompanyRegistration"));
const CompanyList = React.lazy(() => import("./Components/CompanyList"));
const Invoices = React.lazy(() => import("./Components/Invoices"));
const JobAssignmentDashBoard = React.lazy(() => import("./Components/JobAssignmentDashBoard"));
const JobsList = React.lazy(() => import("./Components/JobsList"));
const LoginForm = React.lazy(() => import("./Components/LoginForm"));
const TrooperLandingPage = React.lazy(() => import("./Components/TrooperLandingPage"));
const TrooperList = React.lazy(() => import("./Components/TropperList"));
const TrooperRegistrationForm = React.lazy(() =>
  import("./Components/RegisterTrooperForm")
);
const UnderConstruction = React.lazy(() =>
  import("./Components/UnderConstruction")
);
const Unauthorized = React.lazy(() => import("./Components/Unauthorized"));
const PageNotFound404 = React.lazy(() =>
  import("./Components/PageNotFound404")
);

const LoadingComponent = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
    }}
  >
    <CircularProgress sx={{ color: "white" }} />
  </Box>
);

const LazyWrapper = ({ Component, role, ...rest }) => {
  const userRole = localStorage.getItem("userRole"); // after login save the role under localstorage

  //const userRole = "admin"; //comment this and uncomment above once the role is saved under localstorage
  if (role !== "default" && userRole !== role) {
    return <Navigate to="/unauthorized" />;
  } else {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <Component />
      </Suspense>
    );
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000080",
    },
    base: {
      main: "#ffffff",
    },
    worker: {
      main: "#ff0000",
    },
    action: {
      active: "#000080",
    },
    action2: {
      active: "#087c3d",
    },
  },
});

function App() {
  const route = createBrowserRouter([
    {
      path: "/",
      element: <LazyWrapper Component={LoginForm} role="default" />,
    },
    {
      path: "/register",
      element: <LazyWrapper Component={TrooperRegistrationForm} role="default" />,
    },
    {
      path: "/adminLandingPage",
      element: <LazyWrapper Component={AdminLandingPage} role="admin" />,
    },
    {
      path: "/assistantLandingPage",
      element: (
        <LazyWrapper Component={AssistantLandingPage} role="assistant" />
      ),
    },
    {
      path: "/trooperLandingPage",
      element: <LazyWrapper Component={TrooperLandingPage} role="user" />, 
    },
    {
      path: "/companyRegistration",
      element: <LazyWrapper Component={CompanyRegistration} role="admin" />, 
    },
    {
      path: "/jobAssignmentDashBoard",
      element: <LazyWrapper Component={JobAssignmentDashBoard} role="admin" />, 
    },
    {
      path: "/addNewJobAssignments",
      element: <LazyWrapper Component={AddNewJobAssignments} role="admin" />, 
    },
    {
      path: "/trooperList",
      element: <LazyWrapper Component={TrooperList} role="admin" />,
    },
    {
      path: "/companyList",
      element: <LazyWrapper Component={CompanyList} role="admin" />,
    },
    {
      path: "/jobsList",
      element: <LazyWrapper Component={JobsList} role="admin" />,
      },
    {
        path: "/invoices",
        element: <LazyWrapper Component={Invoices} role="admin" />,
    },
    {
      path: "/underConstruction",
      element: <LazyWrapper Component={UnderConstruction} role="default" />,
    },
    {
      path: "/unauthorized",
      element: <LazyWrapper Component={Unauthorized} role="default" />,
    },
    {
      path: "/pageNotFound",
      element: <LazyWrapper Component={PageNotFound404} role="default" />,
    },
  ]);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={route} />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
